import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import Layout from '../../components/layouts/Layout';
import SEO from '../../components/seo';

import '../../components/postclaim/style.scss';
import scan_img from '../../global/cms/pa_scanning_image.png';
import PostClaimInner from '../../components/ticket_checker/ticket_checker_post_claim_inner';
import { getText } from '../../utils/cms_copy_provider';

const PostClaim = ({ user, entry, config, notification, revealToken, actions, location }) => {
    const loggedIn = (usr) => {
        if (usr.loggedIn) {
            return (
                <div className='text-center'>
                    <p className='welcome text-center'>
                        Welcome
                        <span className='user-name'>{usr.player ? usr.player.name : null}</span>
                    </p>
                </div>
            );
        } else {
            return null;
        }
    };

    return (
        <Layout user={user} config={config} notification={notification} actions={actions}>
            <SEO title='PostClaim' />
            <div className='ticket_checker'>
                <div className='container-fluid primary-container'>
                    <div className='vip-stripe'>
                        <Link to='/ticket_checker' className='w-100 vip-link'>
                            {getText('ticket_checker_title')} <span className='fa fa-chevron-left float-right' aria-hidden='true'></span>
                        </Link>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <div className='scbz-content-area text-center'>
                                {loggedIn(user)}
                                <img src={scan_img} alt='' className='img-responsive center-block scan-image' />
                                <PostClaimInner />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PostClaim);
