import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import CurrentSweeps from '../postclaim/currentSweeps';
import { CheckTicketResponse } from '../enter/EntryResponseMatrix';
import GamePlay from '../reveal/gamePlay';
import CouponDisplay from '../coupon/coupon_display';

import pluralize from 'pluralize';
import { titleCase } from 'title-case';
// import promotion from '../../../promotion_config'; //? keep this for later
import { getText } from '../../utils/cms_copy_provider';

import './style.scss';

const PostClaimInner = ({ entry, config, revealToken, actions, location }) => {
    const [revealEntries, setRevealEntries] = useState(true);

    const onNewTicket = (e) => {
        e.preventDefault();

        actions.entryActions.reset();
        actions.drawTicketActions.reset();
        actions.scratchTicketActions.reset();
        actions.fastPlayTicketActions.reset();
        actions.checkTicketActions.reset();

        navigate('/ticket_checker');
    };

    const bonusAwardedFilter = (bonus) => bonus.result && bonus.result === 'awarded';

    const showEntry = () => {
        const awardedBonuses = entry.last_entry.Bonus ? entry.last_entry.Bonus.filter(bonusAwardedFilter) : [];

        if (awardedBonuses.length) {
            const imgUrl =
                (config.config.image_host &&
                    (config.config.image_host.startsWith('https://') ? config.config.image_host : `https://${config.config.image_host}`)) ||
                '';

            return awardedBonuses.map((bonus) => {
                return bonus.rewards.map((reward) => {
                    if (reward.reward) {
                        // const resolvedCurrency = promotion.resolveCurrency(reward.reward.currency, getText).displayName; //? keep this for later
                        return (
                            <div className='media my-2 text-center claim-box' key={bonus.promotionname}>
                                <div className='container-fluid'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <img
                                                className='img-fluid align-self-center promo-image'
                                                src={`${imgUrl}/pa/promotion_${reward.reward.promotion_id}_thumb.png`}
                                                alt={`${bonus.promotionname} logo`}
                                            />
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='media-body promotion-info-container'>
                                                <p className='mt-1 reward-amount'>{reward.reward.actual_amount}</p>
                                                <p className='mt-1 reward-currency'>
                                                    {pluralize(titleCase(reward.reward.prize_name), reward.reward.actual_amount, false)}
                                                </p>
                                                <p className='mt-1 sweeps-cta'>
                                                    {reward.reward.currency === 'lotteryproducts' ? (
                                                        <CouponDisplay
                                                            prize_name={reward.reward.prize_name}
                                                            prize_description={reward.reward.prize_description}
                                                            entry_data={reward.reward.entry_data}
                                                            redeem={reward.reward.redeem}
                                                            expiration={reward.reward.expiration}
                                                            state={reward.reward.state}
                                                            Url={reward.reward.Url}
                                                            barcodeHost={config?.config?.barcode_image_host}
                                                            linkText='View Coupon'
                                                            expiredText='Expired Coupon'
                                                            redeemedText='Redeemed'
                                                            failedText='Reward pending. Please check back later.'
                                                        />
                                                    ) : reward.reward.state === 'complete' ? (
                                                        <CurrentSweeps key={reward.reward.reward_id} sweepId={reward.reward.sku} />
                                                    ) : (
                                                        <p className='sweeps-cta'>Reward pending. Please check back later.</p>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    } else {
                        return null;
                    }
                });
            });
        }
    };

    function handleRevealEntries(newValue) {
        setRevealEntries(newValue);
    }

    if (entry.last_entry) {
        const lent = entry.last_entry;
        const awardedBonuses = entry.last_entry.Bonus ? entry.last_entry.Bonus.filter(bonusAwardedFilter) : [];

        const showReveal = () => {
            if (awardedBonuses.length) {
                return awardedBonuses.map((bonus) => {
                    if (bonus.reveal.length > 0 && revealToken) {
                        const reveals = bonus.reveal;

                        // pick a reveal from the array of available reveals
                        const randomReveal = reveals[Math.floor(Math.random() * reveals.length)];

                        const outcomeData = {
                            awards: [],
                        };
                        outcomeData.awards.push(...bonus.rewards[0].awards);

                        return (
                            <GamePlay
                                gameServer={config.config.reveal_host}
                                gameId={randomReveal}
                                token={revealToken.revealToken}
                                outcomeData={outcomeData}
                                showEntries={handleRevealEntries}
                            />
                        );
                    }
                });
            }
        };

        return (
            <div className='post-claim primary-page-content'>
                <div className='row'>
                    <div className='col-md-10 offset-md-1'>
                        <div className='description pb-3'>
                            {lent?.TicketDetails?.TicketStatus?.toUpperCase() !== 'DUPLICATE' &&
                            ((lent?.Bonus && !lent.Bonus.length) || lent?.Bonus === null) ? (
                                <CheckTicketResponse
                                    ticketCheckInfo={{ ...lent.TicketDetails, TicketStatus: 'NOT_AVAILABLE' }}
                                    ticketResponseCode={lent.ResultCode}
                                />
                            ) : lent?.TicketDetails?.TicketStatus || lent?.ResultCode === 504 ? ( // explicit check for 504 applies to PA only, this feels like it should be in the EntryResponseMatrix.js however
                                <CheckTicketResponse ticketCheckInfo={lent.TicketDetails} ticketResponseCode={lent.ResultCode} />
                            ) : (
                                <CheckTicketResponse
                                    ticketCheckInfo={{ ...lent.TicketDetails, TicketStatus: 'UNKNOWN' }}
                                    ticketResponseCode={lent.ResultCode}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div id='entry-result-container' className={`row ${revealEntries ? '' : ' hidden'}`}>
                    <div className='col-md-6 offset-md-3 col-xs-10 offset-xs-1'>{showEntry()}</div>
                </div>
                <div className='row'>
                    <div className='col'>{showReveal()}</div>
                </div>
                <div className={`row ${revealEntries ? '' : ' hidden'}`}>
                    <div className='col-md-4 offset-md-4 enter-another'>
                        <p className='have-more'>{getText('ticket_checker_have_more_tickets')}</p>
                        <a href='#' onClick={onNewTicket} className='my-3 check-another btn btn-primary'>
                            {getText('ticket_checker_check_another_ticket')}
                        </a>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className='post-claim'>
                <div className='row'>
                    <div className='col-md-4 offset-md-4'>
                        <a className='mt-4 theme-btn' href='/ticket_checker'>
                            ENTER A TICKET
                        </a>
                    </div>
                </div>
            </div>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PostClaimInner);
